var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "general-function-popup",
    { staticClass: "create-task-popup" },
    [
      _c(
        "div",
        { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
        [
          _c(
            "button",
            {
              class: { hidden: _vm.isStepFirst },
              on: { click: _vm.onMovePrevPopup },
            },
            [
              _c("vue-material-icon", {
                attrs: { name: "keyboard_arrow_left", size: 30 },
              }),
            ],
            1
          ),
          _c("h2", [_vm._v(_vm._s(_vm.taskPopupTitle))]),
          _c(
            "button",
            { staticClass: "close-button", on: { click: _vm.onClosePopup } },
            [_c("vue-material-icon", { attrs: { name: "close", size: 24 } })],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "contents",
          attrs: { slot: "contents" },
          slot: "contents",
        },
        [
          _c("div", { staticClass: "task-equipment-wrapper" }, [
            _c("h3", [_vm._v("설비명(설비코드)")]),
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm.selectedEqpByGroup.eqp["equipmentName"]) +
                  " (" +
                  _vm._s(
                    _vm._f("formatEQPCode")({
                      mEqpId: _vm.selectedEqpByGroup.eqp["mequipmentId"],
                      eqpId: _vm.selectedEqpByGroup.eqp["id"],
                    })
                  ) +
                  ") "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "task-title-wrapper" },
            [
              _c("h3", { staticClass: "required" }, [
                _vm._v(_vm._s(_vm.$t("message.task_new_name_lbl"))),
              ]),
              _c("input-box", {
                attrs: {
                  id: "task-title",
                  type: "text",
                  autocomplete: "off",
                  placeholder: _vm.$t("message.task_new_name_phd"),
                  maxlength: 50,
                  disabled: !_vm.isEditableStep,
                  "is-flex": true,
                },
                model: {
                  value: _vm.taskTitle,
                  callback: function ($$v) {
                    _vm.taskTitle = $$v
                  },
                  expression: "taskTitle",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "task-image-wrapper" }, [
            _c(
              "div",
              { staticClass: "task-image-title" },
              [
                _c("div", [
                  _c("h3", { staticClass: "required" }, [
                    _vm._v(_vm._s(_vm.$t("message.task_new_target_lbl"))),
                  ]),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isEditableStep,
                          expression: "isEditableStep",
                        },
                      ],
                      staticClass: "description",
                      class: { "color-red400": _vm.isFrontErrorFileUpload },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("message.task_new_target_msg")) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "file-basic",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isEditableStep,
                        expression: "isEditableStep",
                      },
                    ],
                    attrs: {
                      color: "gray-border-1",
                      accept: ".jpg, .png",
                      size: "s",
                      width: 100,
                      weight: 400,
                      id: "upFileTargetImage",
                    },
                    on: { change: _vm.fileSelect },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("message.task_new_browse_btn")) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "image-wrapper" }, [
              _vm.imageUrl
                ? _c("div", {
                    staticClass: "image",
                    style: { backgroundImage: "url(" + _vm.imageUrl + ")" },
                  })
                : _c("img", {
                    staticClass: "image-icon",
                    attrs: {
                      src: "/images/image-empty.png",
                      alt: "Task Image Icon",
                    },
                  }),
            ]),
            !_vm.isEditableStep
              ? _c("div", { staticClass: "learn-quality" }, [
                  _c(
                    "div",
                    [
                      _c("h3", [
                        _vm._v(
                          _vm._s(_vm.$t("message.task_detail_result_lbl"))
                        ),
                      ]),
                      _vm._l(Math.round(_vm.learnQuality), function (i) {
                        return _c(
                          "span",
                          { key: "star_" + i, attrs: { "data-icon": "star" } },
                          [
                            _c("vue-material-icon", {
                              attrs: { name: "star", size: 20 },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm._l(Math.round(5 - _vm.learnQuality), function (i) {
                        return _c(
                          "span",
                          {
                            key: "star_border_" + i,
                            attrs: { "data-icon": "star_border" },
                          },
                          [
                            _c("vue-material-icon", {
                              attrs: { name: "star", size: 20 },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  !_vm.errorCode
                    ? _c("p", { staticClass: "description" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("message.task_detail_success_msg")) +
                            " "
                        ),
                      ])
                    : _c("p", { staticClass: "description error" }, [
                        _vm.errorCode === 400
                          ? _c("span", [
                              _vm._v(
                                "Error " +
                                  _vm._s(_vm.$t("message.task_new_error_msg_1"))
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "Fail " +
                                  _vm._s(_vm.$t("message.task_new_error_msg_2"))
                              ),
                            ]),
                        _vm.errorCode === 400
                          ? _c(
                              "a",
                              {
                                staticClass: "error",
                                attrs: {
                                  href: "https://developer.maxst.com/MD/doc/g/tacondition",
                                  target: "_blank",
                                },
                              },
                              [
                                _c("vue-material-icon", {
                                  attrs: { name: "help", size: 14 },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "task-width-wrapper" }, [
            _c("h3", { staticClass: "required" }, [
              _vm._v(_vm._s(_vm.$t("message.task_detail_width_lbl"))),
            ]),
            _c("p", { staticClass: "description" }, [
              _vm._v(_vm._s(_vm.$t("message.task_new_imagewidth_msg"))),
            ]),
            _c(
              "div",
              [
                _c("input-box", {
                  attrs: {
                    id: "task-width",
                    type: "number",
                    autocomplete: "off",
                    disabled: !_vm.isEditableStep,
                    "is-flex": true,
                  },
                  model: {
                    value: _vm.taskWidth,
                    callback: function ($$v) {
                      _vm.taskWidth = $$v
                    },
                    expression: "taskWidth",
                  },
                }),
                _vm.isEditableStep
                  ? _c("drop-down-box", {
                      attrs: {
                        id: "task-width-unit",
                        value: _vm.realWidthUnit,
                        optionItemsData: ["mm", "cm"],
                      },
                      on: { selectedOption: _vm.onSelectedWidthUnit },
                    })
                  : _c("span", [_vm._v(_vm._s(_vm.realWidthUnit))]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "button-basic",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.taskPopupStep !== 2,
              expression: "taskPopupStep !== 2",
            },
          ],
          attrs: {
            slot: "button",
            width: 150,
            "loading-dot": _vm.isLoading,
            disabled: !_vm.isInputAll,
          },
          on: { click: _vm.onMoveNextStep },
          slot: "button",
        },
        [_vm._v(" " + _vm._s(_vm.taskPopupStep === 0 ? "수정" : "다음") + " ")]
      ),
      _c(
        "button-basic",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.taskPopupStep === 2,
              expression: "taskPopupStep === 2",
            },
          ],
          attrs: {
            slot: "button",
            width: 150,
            "loading-dot": _vm.isLoading,
            disabled: _vm.errorCode,
          },
          on: { click: _vm.onCreateTask },
          slot: "button",
        },
        [_vm._v(" 등록 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }