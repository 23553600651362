<template>
  <general-function-popup class="create-task-popup">
    <div slot="title" class="title">
      <button :class="{ hidden: isStepFirst }" @click="onMovePrevPopup">
        <vue-material-icon name="keyboard_arrow_left" :size="30" />
      </button>
      <h2>{{ taskPopupTitle }}</h2>
      <button class="close-button" @click="onClosePopup">
        <vue-material-icon name="close" :size="24" />
      </button>
    </div>
    <div slot="contents" class="contents">
      <div class="task-equipment-wrapper">
        <h3>설비명(설비코드)</h3>
        <span>
          {{ selectedEqpByGroup.eqp['equipmentName'] }}
          ({{
            { mEqpId: selectedEqpByGroup.eqp['mequipmentId'], eqpId: selectedEqpByGroup.eqp['id'] } | formatEQPCode
          }})
        </span>
      </div>
      <div class="task-title-wrapper">
        <h3 class="required">{{ $t('message.task_new_name_lbl') }}</h3>
        <input-box
          id="task-title"
          type="text"
          autocomplete="off"
          v-model="taskTitle"
          :placeholder="$t('message.task_new_name_phd')"
          :maxlength="50"
          :disabled="!isEditableStep"
          :is-flex="true"
        />
      </div>
      <div class="task-image-wrapper">
        <div class="task-image-title">
          <div>
            <h3 class="required">{{ $t('message.task_new_target_lbl') }}</h3>
            <p v-show="isEditableStep" class="description" :class="{ 'color-red400': isFrontErrorFileUpload }">
              {{ $t('message.task_new_target_msg') }}
            </p>
          </div>
          <file-basic
            v-show="isEditableStep"
            color="gray-border-1"
            accept=".jpg, .png"
            size="s"
            :width="100"
            :weight="400"
            id="upFileTargetImage"
            @change="fileSelect"
          >
            {{ $t('message.task_new_browse_btn') }}
          </file-basic>
        </div>
        <div class="image-wrapper">
          <div v-if="imageUrl" class="image" :style="{ backgroundImage: `url(${imageUrl})` }" />
          <img v-else src="/images/image-empty.png" class="image-icon" alt="Task Image Icon" />
        </div>
        <div class="learn-quality" v-if="!isEditableStep">
          <div>
            <h3>{{ $t('message.task_detail_result_lbl') }}</h3>
            <span data-icon="star" v-for="i in Math.round(learnQuality)" :key="`star_${i}`">
              <vue-material-icon name="star" :size="20" />
            </span>
            <span data-icon="star_border" v-for="i in Math.round(5 - learnQuality)" :key="`star_border_${i}`">
              <vue-material-icon name="star" :size="20" />
            </span>
          </div>
          <p v-if="!errorCode" class="description">
            {{ $t('message.task_detail_success_msg') }}
          </p>
          <p v-else class="description error">
            <span v-if="errorCode === 400">Error {{ $t('message.task_new_error_msg_1') }}</span>
            <span v-else>Fail {{ $t('message.task_new_error_msg_2') }}</span>
            <a
              href="https://developer.maxst.com/MD/doc/g/tacondition"
              target="_blank"
              class="error"
              v-if="errorCode === 400"
            >
              <vue-material-icon name="help" :size="14" />
            </a>
          </p>
        </div>
      </div>
      <div class="task-width-wrapper">
        <h3 class="required">{{ $t('message.task_detail_width_lbl') }}</h3>
        <p class="description">{{ $t('message.task_new_imagewidth_msg') }}</p>
        <div>
          <input-box
            id="task-width"
            type="number"
            autocomplete="off"
            v-model="taskWidth"
            :disabled="!isEditableStep"
            :is-flex="true"
          />
          <drop-down-box
            v-if="isEditableStep"
            id="task-width-unit"
            :value="realWidthUnit"
            :optionItemsData="['mm', 'cm']"
            @selectedOption="onSelectedWidthUnit"
          />
          <span v-else>{{ realWidthUnit }}</span>
        </div>
      </div>
    </div>
    <button-basic
      v-show="taskPopupStep !== 2"
      slot="button"
      :width="150"
      :loading-dot="isLoading"
      :disabled="!isInputAll"
      @click="onMoveNextStep"
    >
      {{ taskPopupStep === 0 ? '수정' : '다음' }}
    </button-basic>
    <button-basic
      v-show="taskPopupStep === 2"
      slot="button"
      :width="150"
      :loading-dot="isLoading"
      :disabled="errorCode"
      @click="onCreateTask"
    >
      등록
    </button-basic>
  </general-function-popup>
</template>
<script>
import GeneralFunctionPopup from '@/component/popup/generalFunctionPopup/GeneralFunctionPopup';
import { mapActions, mapMutations, mapState } from 'vuex';
import fileUpload from '@/asset/js/fileUpload';
import InputBox from '@/component/input/InputBox';
import DropDownBox from '@/component/dropDown/DropDownBox';

export default {
  name: 'CreateTaskPopup',
  props: ['projectId', 'selectedTask'],
  data() {
    return {
      taskPopupStep: this.selectedTask ? 0 : 1, // 0: 상세, 1: 수정, 2: 수정2 or 1: 등록, 2: 등록2
      imageUrl: '',
      allFilename: '',
      fileBase64: '',
      taskTitle: '',
      taskWidth: '',
      learnQuality: 0,
      errorCode: null,
      rscUuid: null,
      targetId: null,
      isFrontErrorFileUpload: false,
      isLoading: false,
      realWidthUnit: 'cm',
    };
  },
  computed: {
    ...mapState('factoryEquipments', ['selectedEqpByGroup']),
    content() {
      return this.selectedTask.saveContent ? this.selectedTask.saveContent : this.selectedTask.publishContent;
    },
    isInputAll() {
      return this.taskTitle.trim() && this.imageUrl && this.taskWidth;
    },
    isStepFirst() {
      if (this.selectedTask) {
        return this.taskPopupStep === 0;
      } else {
        return this.taskPopupStep === 1;
      }
    },
    isEditableStep() {
      return this.taskPopupStep === 1;
    },
    createTaskReqBody() {
      return {
        name: this.taskTitle.trim(),
        manualTarget: {
          id: this.targetId || null,
          type: 'img',
          rscName: this.allFilename || this.content.manualTarget.rscName,
          rscFileData: this.fileBase64,
          rscUuid: this.rscUuid,
          realWidth: Number(this.taskWidth) || this.content.manualTarget.realWidth,
          realWidthUnit: this.realWidthUnit,
          learnQuality: this.learnQuality,
        },
      };
    },
    taskPopupTitle() {
      if (this.selectedTask) {
        return this.isStepFirst ? '상세 정보' : '수정';
      } else {
        return '타깃이미지 등록';
      }
    },
  },
  created() {
    if (this.selectedTask) {
      this.initializeEditTask();
    }
  },
  mounted() {},
  watch: {
    taskPopupStep() {
      if (this.taskPopupStep === 0) {
        this.initializeEditTask();
      }
    },
  },
  methods: {
    ...mapMutations('manualTasks', ['SET_SELECTED_TASK', 'SET_IS_CHANGE_TARGET']),
    ...mapActions('manualTargets', ['POST_LEARN_QUALITY']),
    ...mapActions('manualTasks', ['GET_PROJECT_TASKS', 'PUT_TASK', 'POST_TASK']),
    onClosePopup() {
      this.$emit('close');
    },
    onSelectedWidthUnit(widthUnit) {
      this.realWidthUnit = widthUnit;
    },
    initializeEditTask() {
      this.errorCode = null;
      this.allFilename = '';
      this.taskTitle = this.content.name;
      if (this.content.manualTarget.realWidthUnit === 'm') {
        this.taskWidth = this.content.manualTarget.realWidth * 100;
        this.realWidthUnit = 'cm';
      } else {
        this.taskWidth = this.content.manualTarget.realWidth;
        this.realWidthUnit = this.content.manualTarget.realWidthUnit;
      }
      this.imageUrl = this.content.manualTarget.rscURL;
      this.targetId = this.content.manualTarget.id;
      this.learnQuality = this.content.manualTarget.learnQuality;
    },
    onMovePrevPopup() {
      this.taskPopupStep--;
    },
    onMoveNextStep() {
      if (this.allFilename) {
        this.postLearnQuality();
      } else {
        this.taskPopupStep++;
      }
    },
    fileSelect(e) {
      fileUpload(this, e.target, false, true);
    },
    toggleIsLoading() {
      this.isLoading = !this.isLoading;
    },
    async postLearnQuality() {
      try {
        this.toggleIsLoading();
        const resData = await this.POST_LEARN_QUALITY({
          rscName: this.allFilename,
          rscFileData: this.fileBase64,
        });
        this.learnQuality = resData.learnQuality;
        this.errorCode = null;
        this.rscUuid = resData.rscUuid;
      } catch (error) {
        this.errorCode = error.response.status;
        this.learnQuality = 0;
      } finally {
        this.taskPopupStep++;
        this.toggleIsLoading();
      }
    },
    onCreateTask() {
      if (this.selectedTask) {
        this.putTask();
      } else {
        this.postTask();
      }
    },
    setStoreData(data) {
      this.SET_SELECTED_TASK(data);
      if (this.fileBase64) {
        this.SET_IS_CHANGE_TARGET(true);
      }
    },
    async putTask() {
      try {
        this.toggleIsLoading();
        const resData = await this.PUT_TASK([this.selectedTask.id, this.createTaskReqBody]);
        this.setStoreData(resData);
        this.$emit('close');
        await this.GET_PROJECT_TASKS(resData['manualProjectId']);
      } finally {
        this.toggleIsLoading();
      }
    },
    async postTask() {
      try {
        this.toggleIsLoading();
        const resData = await this.POST_TASK([this.projectId, this.createTaskReqBody]);
        this.$emit('close');
        this.setStoreData(resData);
        await this.GET_PROJECT_TASKS(resData['manualProjectId']);
      } finally {
        this.toggleIsLoading();
      }
    },
  },
  components: { DropDownBox, InputBox, GeneralFunctionPopup },
};
</script>
<style scoped lang="scss">
@import 'TaskPopup';
</style>
