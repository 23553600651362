import isEmptyObject from '@/asset/js/isEmptyObject';

export default (_this) => {
  if (isEmptyObject(_this.selectedEqpByGroup)) {
    return '';
  }
  const eqpCode = _this.$options.filters.formatEQPCode({
    mEqpId: _this.selectedEqpByGroup.eqp['mequipmentId'],
    eqpId: _this.selectedEqpByGroup.eqp['id'],
  });
  return `${_this.selectedEqpByGroup.eqp['equipmentName']} (${eqpCode})`;
};
